import React, { useEffect, useRef, useState } from 'react';
// import { components } from 'react-select';
import { useNavigate, useParams } from 'react-router-dom';
import MasterService from '../../services/MasterService';
import UserMappingHistoryTopFilter from "./UserMappingHistoryTopFilter"
import DateFormat from 'dateformat';
import Loader from '../common/loader';
import ReactPaginate from 'react-paginate';
import { CSVLink } from 'react-csv';
// const { ValueContainer, Placeholder } = components;

// const statusOptions = [
//     { value: 'All', label: 'All' },
//     { value: '1', label: 'Completed' },
//     { value: '0', label: 'Pending' },
// ]

const UserMappingHistory = (props) => {

    const csvLink = useRef();
    let { user_id } = useParams()
    const [mappingHistoryData, setMappingHistoryData] = useState([]);
    const [pageCount, setPageCount] = useState(1)
    const [offset, setOffset] = useState(0);
    const [csvData, setCsvData] = useState([])
    const [userName, setUserName] = useState('')
    const [loading, setLoading] = useState(false);
    // const [isReset, setReset] = useState(0);
    const [isPageReset, setPageReset] = useState(0);
    const [search_params, setSearchParams] = useState({})

    const historyLead = useNavigate();

    useEffect(() => {
        setOffset(0)
    }, [props.isPageReset])

    useEffect(() => {
        let postData = {
            "page": 1,
            "user_id": user_id,
            "mapping_status": ""
        }
        getHistoryList(1, postData);
        getUserDetail();
    }, []);



    const handlePageClick = (e) => {
        const selectedPage = e.selected
        setOffset(selectedPage)
        search_params.page = selectedPage + 1;
        getHistoryList(selectedPage + 1, search_params, isPageReset)
    };

    const downloadData = async () => {
        let csv_data = await getExportData();
        setCsvData(csv_data)
        csvLink.current.link.click();
    }

    const isDate = (date) => {
        return (new Date(date) !== "Invalid Date") && !isNaN(new Date(date));
    }

    const getHistoryList = async (page_no, params, page_reset) => {
        setLoading(true);
        if (page_reset) {
            setPageReset(isPageReset + 1)
        }
        let temp_search_params = { ...search_params }
        if (Object.keys(params).length) {
            setSearchParams(params)
            temp_search_params = params
        } else {
            temp_search_params.page = page_no
        }
        MasterService.post('/sfa/dealer/dealer-mapping-history', temp_search_params)
            .then(function (response) {
                if (response && response.data && response.data.status === 200 && response.data.data) {
                    setMappingHistoryData(response.data.data);
                    let totalPage = response.data.data.count;
                    let recordPerPage = response.data.data.recordPerPage;
                    setPageCount(Math.ceil(totalPage/recordPerPage))
                }
                setLoading(false);
            })
            .catch(function (response) {
            });
    }


    const getExportData = async () => {
        setLoading(true)
        let result = []
        await MasterService.post('/sfa/dealer/export-dealer-mapping-history', search_params)
            .then(function (response) {
                if (response && response.data && response.data.status === 200 && response.data.data.csv_data) {
                    result = response.data.data.csv_data;
                }
            })
            .catch(function (response) {
            });
        setLoading(false)
        return result
    }

    const getUserDetail = async () => {
        setLoading(true)
        await MasterService.post('/sfa/user/get-view-detail', { user_id }).then(async function (response) {
            if (response.data.status === 200 && response.data.data && response.data.data.status !== '5') {
                let user_data = response.data.data
                setUserName(user_data.name)

            }
        })
        setLoading(false);
    }
    return (
        <React.Fragment>
            {loading ? <Loader /> : null}
            <div className='container-fluid'>
                <div className="top-heading d-flex">
                    <h1>Mapping History {userName ? '( ' + userName + ' )' : ''}</h1>

                    <div className='visit-filter-outer'>
                    
                   
                    <button className='btn-line m-md-r' onClick={historyLead(-1)}>
                        Back
                    </button>
                    <button className="btn-line">
                        <i className="ic-export m-xs-r"></i>
                        <span onClick={() => downloadData()} >Export Data</span>
                        <CSVLink
                            data={csvData}
                            filename={`user-mapping-list-${Math.round(+new Date() / 1000)}.csv`}
                            data-interception='off'
                            ref={csvLink}
                        >
                        </CSVLink>
                    </button>
                     </div>
                </div>
            

                <div className="top-filter-bg">
                    <UserMappingHistoryTopFilter userId={user_id} setSearch={getHistoryList} />
                </div>
            <div className='map-dealer-outer'>
            <div className='executive-listing data-table user-visit-table all-executive-listing-table'>
                
                <table>
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>AMBK Code</th>
                            <th>Dealer</th>
                            <th>Status</th>
                            <th>Updated By</th>
                        </tr>
                    </thead>
                    <tbody>
                        {mappingHistoryData.data?.length > 0 && mappingHistoryData.data.map((element, index) => (
                            <tr>
                                <td>{isDate(element.created_date) ? DateFormat(new Date(element.created_date), 'dd mmm yyyy') : "-"}</td>
                                <td> <span>{element.gcd_code ? element.gcd_code : "-"} </span></td>
                                <td><span>{element.dealer_name}</span></td>
                                <td><span>{element.mapping_status === 1 ? "Mapped" : element.mapping_status === 0 ? "Unmapped" : ""}</span></td>
                                <td><span>{element.update_by_name}</span></td>
                            </tr>
                        ))}

                    </tbody>
                </table>
                <div className='pagination-bx' id="dealerUserList">
                    <ReactPaginate
                        previousLabel={"<"}
                        nextLabel={">"}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        forcePage={offset}
                        pageRangeDisplayed={5}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination"}
                        subContainerClassName={"pages pagination"}
                        activeClassName={"active"} />
                </div >
            </div>
            </div>
            </div>

        </React.Fragment>
    )
    // }
}
export default UserMappingHistory;