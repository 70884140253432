import React, {useEffect, useState } from 'react';
import Select, { components } from 'react-select';
import { Business, defaultBusiness, defaultZone } from '../../config/constant';
import { MasterService } from '../../services';
import { toast } from 'react-toastify';
// import Loader from '../common/loader';
// import Dropdown from 'react-bootstrap/Dropdown'
// import { Button, Dropdown, Tab, Tabs, Nav } from 'react-bootstrap';
const { ValueContainer, Placeholder } = components;
const CustomValueContainer = ({ children, ...props }) => {
    return (
        <ValueContainer {...props}>
            <Placeholder {...props} isFocused={props.isFocused}>
                {props.selectProps.placeholder}
            </Placeholder>
            {React.Children.map(children, child =>
                child && child.type !== Placeholder ? child : null
            )}
        </ValueContainer>
    );
};


const MapFilter = (props) => {
    let { type, activeTab } = props
    const [ setZone] = useState(defaultZone)
    const [verticalValue, setVertical] = useState(defaultBusiness)
    const [stateList, setStateList] = useState([])
    const [cityList, setCityList] = useState([])
    const [city, setCity] = useState(null);
    const [state, setState] = useState(null);
    const [dealerData, setDealerData] = useState(null);
    const [executiveData, setExecutiveData] = useState(null);
    const [dealer, setDealer] = useState(null);
    const [executive, setExecutive] = useState(null);

    useEffect(() => {
        getStateList()
    }, [])

    useEffect(() => {
        setCity(null)
        setState(null)
        setDealerData(null)
        setDealer(null)
        setExecutiveData(null)
        setExecutive(null)
    }, [props.filter_reset])

    useEffect(() => {
        if (props.listing_state) {
            let { city_id, city_name, state_id, state_name, dealer_id, dealer_name, type, user_id, user_name } = props.listing_state
            let selected_city = { id: city_id, label: city_name, value: city_id }
            let selected_state = { id: state_id, label: state_name, value: state_id }
            let selected_dealer = { dealer_code: dealer_id, id: dealer_id, label: dealer_name, value: dealer_id }
            let selected_executive = { id: user_id, label: user_name, user_id: user_id, value: user_id }
            setCity(selected_city)
            setState(selected_state)
            if (type === "dealer") {
                setDealer(selected_dealer)
                getDealerListing(selected_dealer)
                let params = { "vertical_id": 1, "page": 1, status: '1', per_page: 50, dealer_id: dealer_id, city_id:city_id }
                props.handleChangeDealer(params)
            }
            if (type === "executive") {
                setExecutive(selected_executive)
                getExecutiveListing(selected_executive)
                let params = { "vertical_id": 1, "page": 1, status: '1', per_page: 50, user_id: user_id, city_id:city_id }
                props.handleChangeExecutive(params)
            }

        }
    }, [props.listing_state])

    useEffect(() => {
        getDealerListing()
        getExecutiveListing()
    }, [activeTab]);

    const handleChange = (event, name) => {
        if (name === "zone") {
            setZone(event.id)
        } else if (name === "vertical") {
            setVertical(event.id)
        } else if (name === "state") {
            if(type === "dealer")
            props.clearDealersDetails();
            if (type === "executive")
            props.clearExecutiveDetails();
            setState(event)
            setCity(null)
            getCityList(event, true)
        } else if (name === "city") {
            setCity(event)
            if (type === "dealer") {
                setDealer(null)
                getDealerListing(event)
            }
            if (type === "executive") {
                setExecutive(null)
                getExecutiveListing(event)
            }
        } else if (name === "dealer") {
            if (dealerData && dealerData.length) {
                setDealer(event)
                let params = { "vertical_id": 1, "page": 1, status: '1', per_page: 50, dealer_id: event.id, city_id: city?city.id:""}
                props.handleChangeDealer(params)
            }

        } else if (name === "executive") {
            if (executiveData && executiveData.length) {
                setExecutive(event)
                let params = { "vertical_id": 1, "page": 1, status: '1', per_page: 50, user_id: event.id, city_id: city?city.id:"" }
                props.handleChangeExecutive(params)
            }
        }
    };

    const getStateList = async () => {
        MasterService.post('/sfa/user/state_list', {})
            .then(function (response) {
                if (response.data.status === 200) {
                    var state_list = response.data.data.map(elm => ({ id: elm.id, label: elm.state_name, value: elm.id }));
                    setStateList(state_list);
                } else {
                    toast.error(response.data.message);
                }
            })
            .catch(function (response) {
            });
    }

    const getCityList = async (state_id, state_change) => {
        if (!state_id && state_change) {
            setCityList([])
        } else {
            await MasterService.post('/sfa/user/city_list', { state_ids: [state_id.id] })
            .then(function (response) {
                if (response.data.status === 200) {
                    var city_list = response.data.data.map(elm => ({ id: elm.id, label: elm.city_name, value: elm.id, state_id: elm.state_id }));
                    setCityList(city_list);
                } else {
                    toast.error(response.data.message);
                }
            })
            .catch(function (response) {
            });
        }
    }

    const getDealerListing = async (temp_city) => {
        let searchData = {
            search_text: '',
            city_id: temp_city ? temp_city.id : '',
            state_id: state ? state.id : '',
            apply_limit: "no",
            vertical_id: 1,
            page: 1,
            status:'1'
        }
        await MasterService.post('/sfa/dealer/dealerReportingResult', searchData)
            .then(async function (response) {
                if (response.data.status === 200) {
                    let dealer_names = response.data.data.dealer_data.map((e) => ({
                        label: `${e.dealer_name ? e.dealer_name.trim() : ""} (${e.dealer_code})`,
                        dealer_code: e.dealer_code,
                        id: e.dealer_code,
                        value: e.dealer_code
                    }))
                    dealer_names = await sort(dealer_names, 'label')
                    setDealerData(dealer_names);
                } else {
                    if (response.data.error && response.data.error.length > 0) {
                        response.data.error.forEach(element => {
                            toast.error(element);
                        });
                    } else {
                        toast.error(response.data.message);
                    }
                }
            })
            .catch(function (response) {
            });
    }

    const getExecutiveListing = async (temp_city) => {
        let searchData = {
            search_text: '',
            city_id: temp_city ? [temp_city.id] : '',
            vertical_id: 1,
            page: 1
        }
        await MasterService.post('/sfa/dealer/executiveListByCities', searchData)
            .then(async function (response) {
                if (response.data.status === 200) {
                    let executive_names = response.data.data.executive_data.map((e) => ({
                        label: `${e.name ? e.name.trim() : ""} (${e.id})`,
                        id: e.id,
                        value: e.id,
                        user_id: e.id
                    }))
                    executive_names = await sort(executive_names, 'label')
                    setExecutiveData(executive_names);
                } else {
                    if (response.data.error && response.data.error.length > 0) {
                        response.data.error.forEach(element => {
                            toast.error(element);
                        });
                    } else {
                        toast.error(response.data.message);
                    }
                }
            })
            .catch(function (response) {
            });
    }

    const sort = (array, key) => {
        const sortedList = array.sort((a, b) => {
            const isALabelValid = a[key] && !a[key].startsWith(' (');
            const isBLabelValid = b[key] && !b[key].startsWith(' (');
            if (isALabelValid && isBLabelValid) {
                return a[key].localeCompare(b[key], 'en-US', { caseFirst: 'upper' });
            }
            if (isALabelValid) return -1;
            if (isBLabelValid) return 1;
            return a[key].localeCompare(b[key], 'en-US', { caseFirst: 'upper' });
        });
    
        return sortedList;
    };
    

    return (
        <React.Fragment>
            <div className="map-filter">
                <fieldset className="single-select">
                    <div className="material">
                        <Select components={{ ValueContainer: CustomValueContainer }}
                            onChange={(e) => handleChange(e, 'vertical')}
                            options={Business}
                            placeholder="Select Vertical"
                            className="react-select"
                            classNamePrefix="react-select"
                            value={Business.filter(e => e.id === verticalValue)}
                        />
                    </div>
                </fieldset>
                <fieldset className="single-select">
                    <div className="material">
                        <Select components={{ ValueContainer: CustomValueContainer }}
                            value={state}
                            onChange={(e) => handleChange(e, 'state')}
                            options={stateList}
                            placeholder="Select State"
                            className="react-select"
                            classNamePrefix="react-select"
                        />
                    </div>
                </fieldset>
                <fieldset className="single-select">
                    <div className="material">
                        <Select components={{ ValueContainer: CustomValueContainer }}
                            value={city}
                            isDisabled={state && state.id ? false : true}
                            onChange={(e) => handleChange(e, 'city')}
                            options={cityList}
                            placeholder="Select City"
                            className="react-select"
                            classNamePrefix="react-select"
                        />
                    </div>
                </fieldset>

                <fieldset className="single-select">
                    <div className="material">
                        {type === 'dealer' &&
                            <Select components={{ ValueContainer: CustomValueContainer }}
                                value={dealer}
                                // isDisabled={(city && city.id) ? false : true}
                                onChange={(e) => handleChange(e, 'dealer')}
                                options={dealerData}
                                placeholder="Select Partner"
                                className="react-select"
                                classNamePrefix="react-select"
                            />
                        }
                        {type === 'executive' &&
                            <Select components={{ ValueContainer: CustomValueContainer }}
                                value={executive}
                                // isDisabled={(city && city.id) ? false : true}
                                onChange={(e) => handleChange(e, 'executive')}
                                options={executiveData}
                                placeholder="Select Executive"
                                className="react-select"
                                classNamePrefix="react-select"
                            />
                        }
                    </div>
                </fieldset>
            </div>
        </React.Fragment>
    )
}
export default MapFilter;