import React, { useEffect, useState } from 'react';
import Select, { components } from 'react-select';
import ReactPaginate from 'react-paginate';
import {Tooltip} from 'react-tooltip';
import InputField from '../common/Input';
import { useNavigate } from 'react-router-dom';

const { ValueContainer, Placeholder } = components;
const CustomValueContainer = ({ children, ...props }) => {
    return (
        <ValueContainer {...props}>
            <Placeholder {...props} isFocused={props.isFocused}>
                {props.selectProps.placeholder}
            </Placeholder>
            {React.Children.map(children, child =>
                child && child.type !== Placeholder ? child : null
            )}
        </ValueContainer>
    );
};

const ExecutiveListingTable = (props) => {
    const [executiveData, setExecutiveData] = useState(null);
    const [pageCount, setPageCount] = useState(1)
    const [offset, setOffset] = useState(0);
    const [stateList, setStateList] = useState([])
    const [cityList, setCityList] = useState([])
    const [city, setCity] = useState(null);
    const [state, setState] = useState(null);
    const [tipOpen, setTipOpen] = useState(false);
    const [tipOpenIndex, setTipOpenIndex] = useState(0);
    const [search_text, setSearchText] = useState('')
    const historyLead = useNavigate();

    useEffect(() => {
        setExecutiveData(props.executiveData)
        setPageCount(props.pageCount)
        setCityList(props.cityList)
        setStateList(props.stateList)
    }, [props.executiveData, props.pageCount, props.cityList, props.stateList])

    useEffect(() => {
        setOffset(0)
    }, [props.isPageReset])

    useEffect(() => {
        if (props.isReset) {
            handleReset()
        }
    }, [props.isReset])

    useEffect(() => {
        if (props.isPopUpReset) {
            toggleTip(0, 'close', true)
        }
    }, [props.isPopUpReset])

    const handleReset = () => {
        setStateList([])
        setCityList([])
        setCity(null)
        setState(null)
        setExecutiveData(null)
        setPageCount(1)
        setOffset(0)
        setSearchText('')
        handleSearch(true)
    }

    const toggleTip = (index, state, reset) => {
        if (state === 'close') {
            index = 0
        }
        if (reset) {
            setTipOpen(false)
        } else {
            setTipOpen(!tipOpen)
        }

        setTipOpenIndex(index)
    }

    const handlePageClick = (e) => {
        const selectedPage = e.selected
        setOffset(selectedPage)
        let search_data = {
            search_text: search_text ? search_text : '',
            city_id: city ? city.id : '',
            state_id: state ? state.id : '',
            status: 1
        }
        props.handleSearchData(search_data)
        props.getExecutiveReporting(selectedPage + 1, search_data, false)
    };

    const handleChange = (event, name) => {
        if (name === "state") {
            setState(event)
            setCity(null)
            props.getCityList(event, true)
        } else if (name === "city") {
            setCity(event)
        }
    }

    const handleInputChange = async (target_name, type, event) => {
        if (target_name === "search_text") {
            setSearchText(event.target.value)
        }
    }

    const handleSearch = (is_reset) => {
        setOffset(0)
        let search_data = {
            search_text: search_text ? search_text : '',
            city_id: city ? city.id : '',
            state_id: state ? state.id : '',
            page: 1
        }
        if (is_reset) {
            search_data = {
                city_id: "",
                page: 1,
                search_text: "",
                state_id: "",
                status: 1,
                vertical_id: 1
            }
        }
        if (tipOpen) {
            toggleTip(0, 'close', true)
        }
        props.handleSearchData(search_data)
        props.getExecutiveReporting(offset + 1, search_data, true)
    }

    const handleView = (executive) => {
        let params = {
            city_id: executive.city_id ? executive.city_id : '',
            state_id: executive.state_id ? executive.state_id : '',
            user_id: executive.user_id,
            city_name: executive.city,
            state_name: executive.state,
            user_name: executive.name,
            type: 'executive'
        }
        historyLead({
            pathname: "/map-dealer",
            state: params
        });
    }

    return (
        <React.Fragment>
            <div className="table-heading-filter">
                <h2>Executive Listing</h2>
                <div className="filter-right-panel">
                    <fieldset className="search-bx">
                        <div className="material">
                            <div className="material">
                                <InputField type="text" name="search_text" id="search_text" label="Search By Name" placeholder="Search By Name Or Id" value={search_text} onChange={handleInputChange.bind(null, 'search_text', 'text')} />
                                <i className="ic-search"></i>
                            </div>
                        </div>
                    </fieldset>
                    <fieldset className="single-select">
                        <div className="material">
                            <Select components={{ ValueContainer: CustomValueContainer }}
                                value={state}
                                onChange={(e) => handleChange(e, 'state')}
                                options={stateList}
                                placeholder="Select State"
                                className="react-select"
                                classNamePrefix="react-select"
                            />
                        </div>
                    </fieldset>
                    <fieldset className="single-select">
                        <div className="material">
                            <Select components={{ ValueContainer: CustomValueContainer }}
                                value={city}
                                isDisabled={state && state.id ? false : true}
                                onChange={(e) => handleChange(e, 'city')}
                                options={cityList}
                                placeholder="Select City"
                                className="react-select"
                                classNamePrefix="react-select"
                            />
                        </div>
                    </fieldset>
                    <div className="btn-submit-reset m-sm-l">
                        <button className='btn-primary' onClick={() => handleSearch(false)}>Search</button>
                        <button className="btn-reset" onClick={() => handleReset()}>Reset</button>
                    </div>
                </div>

            </div>
            <div className='executive-listing data-table user-visit-table all-executive-listing-table'>
                <table>
                    <thead>
                        <tr>
                            <th>User Details</th>
                            <th>Role</th>
                            <th>Email ID</th>
                            <th>Reporting Manager</th>
                            <th>Assigned Partners</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {executiveData?.length > 0 && executiveData.map((dealers, index) => (
                            <tr>
                                <td>
                                    <span className='d-block'>{dealers.name}</span>
                                    <span>{dealers.user_id ? "(SFA ID - " + dealers.user_id + ")" : ""}</span>
                                </td>
                                <td>{dealers.role_name ? dealers.role_name : "-"}</td>
                                <td>{dealers.email ? dealers.email : "-"}</td>
                                <td>{(dealers.reporting_manager_name ? dealers.reporting_manager_name : "") + (dealers.reporting_manager_role_name ? " (" + dealers.reporting_manager_role_name + ")" : "-")}</td>
                                <td>{dealers.dealercount ? dealers.dealercount : "-"}
                                    {dealers.dealercount > 0 && <Tooltip
                                        content={(
                                            <div className="controlled-example tooltip-main">
                                                <div className="tooltip-data">
                                                    <span className="controlled-example_close-button" onClick={() => toggleTip(index, 'close')}><i className="ic-clearclose"></i></span>
                                                    <ul className="user-detail">
                                                        {dealers.assigned_dealer_data?.length && dealers.assigned_dealer_data.map((assing_dealer, a_index) => (
                                                            <li>{assing_dealer.dealer_name + (assing_dealer.id ? " (AMBK-" + assing_dealer.id + ")" : "")}</li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            </div>
                                        )}
                                        className="tootip-outer"
                                        tipContentClassName="tootip-outer"
                                        tagName="span"
                                        isOpen={((index === tipOpenIndex) && tipOpen) ? true : false}
                                        useHover={false}
                                        direction="down"
                                        forceDirection
                                    >
                                        <span onClick={() => toggleTip(index, 'open')} title="" className="tooltio-ic"> <i className='ic-info-icon1'></i>
                                        </span>
                                    </Tooltip>
                                    }
                                </td>
                                <td>
                                    <ul className="action-btn ul-li-horizontal" >
                                        <li onClick={(e) => handleView(dealers)}><i className="ic-edit"></i></li>
                                        <li onClick={() =>  historyLead.push('/user-mapping-history/' + dealers.user_id)}>
                                            <i className="ic-Call-history"></i>
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <div className='pagination-bx' id="dealerUserList">
                    <ReactPaginate
                        previousLabel={"<"}
                        nextLabel={">"}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        forcePage={offset}
                        pageRangeDisplayed={5}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination"}
                        subContainerClassName={"pages pagination"}
                        activeClassName={"active"} />
                </div >
            </div>
        </React.Fragment>
    )
}
export default ExecutiveListingTable;