import Select from 'react-select';
import { InputOption, MultiSelectValueContainer } from './MultiselectCustomComponent';

function MultiSelect({
    options,
    value,
    onChange,
    placeholder,
    optionLabel = 'label',
    optionValue = 'value',
    isDisabled = false,
    isClearable = false
}) {
    const getDropdownButtonLabel = () => {
        if (value && value.length === 0) {
            return `${placeholder}`;
        } else if (value && value.some((o) => o[optionValue] === "*")) {
            return `All selected (${value.length - 1})`;
        } else if (value && value.length) {
            return `${value.length} selected`;
        }
        return placeholder; 
    };

    const handleChange = (selectedOptions, actionMeta) => {
        const event = {
            action: actionMeta.action,
            option: actionMeta.option,
        };
        onChange(selectedOptions, event); 
    };

    return (
        <Select
            components={{
                Option: InputOption,
                ValueContainer: MultiSelectValueContainer
            }}
            isMulti
            getDropdownButtonLabel={getDropdownButtonLabel}
            options={options}
            placeholder={placeholder}
            value={value}
            getOptionLabel={(obj) => obj[optionLabel]}
            getOptionValue={(obj) => obj[optionValue]}
            onChange={handleChange}
            isDisabled={isDisabled}
            isClearable={isClearable}
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            backspaceRemovesValue={false}
            className="react-select react-multiselect-opt"
            classNamePrefix="react-select"
        />
    );
}

export default MultiSelect;

