import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import Select, { components } from 'react-select'
import { Business, Vertical } from '../../config/constant';
import MultiSelect from '../element/MultiSelect'
import { defaultZone, Zone } from '../../config/constant';
import { MasterService } from '../../services';
import InputField from '../element/Input';

const { ValueContainer, Placeholder } = components;

const AddRoleDetailsComponent = (props) => {
    const { roleDetails, status, stateList } = props;
    const [roleDetailsInfo, setRoleDetailsInfo] = useState({ zone_id: defaultZone });
    const [ setCurrentStatus] = useState([])
    const [stateData, setStateData] = useState([])
    const [cityData, setCityData] = useState([])
    const [cityList, setCityList] = useState([])
    const [errors, setErrors] = useState({})
    const [isEdit, setIsEdit] = useState(roleDetails && roleDetails.role_id && roleDetails.role_id !== "" ? true : false);
    const historyLead = useNavigate();

    useEffect(() => {
        if (roleDetails.role_details_info) {
            if (roleDetails.role_id && roleDetails.role_id !== "") {
                setIsEdit(true);
            }
            getCityAndStateData({
                state: roleDetails.role_details_info.state,
                city: roleDetails.role_details_info.city,
                zone_id: defaultZone,
            }, true);
            setRoleDetailsInfo(prevState => {
                return {
                    ...prevState,
                    ...roleDetails.role_details_info
                }
            })
        }
    }, [roleDetails])

    const getCityAndStateData = async (mapRegion, setData) => {
        await getCityList(mapRegion.state, setData, mapRegion)
        let state_data = stateList.filter(e => mapRegion.state.includes(e.id))
        if (state_data && state_data.length > 0) {
            setStateData(state_data)
        }
    }

    const getDropdownButtonLabel = ({ placeholderButtonLabel, value }) => {
        let text = placeholderButtonLabel.split("*")[0]
        if (value && value.some((o) => o.value === "*")) {
            return `All ${text} selected`;
        } else if (value && value.length === 0) {
            return `${placeholderButtonLabel}`;
        } else if (value && value.length) {
            if (text === "State" && value?.length === stateList.length) {
                return `All ${text} selected`;
            } else if (text === "City" && value?.length === cityList.length) {
                return `All ${text} selected`;
            } else {
                return `${value.length} ${text} selected`;
            }
        } else {
            return `${placeholderButtonLabel}`;
        }
    }

    const getCityList = async (state_ids, setData, mapRegion) => {
        let temp_role_details = mapRegion;
        if (state_ids.length === 0) {
            temp_role_details["city"] = []
            setCityData([])
            setCityList([])
        } else {
            await MasterService.post('/sfa/user/city_list', { state_ids })
                .then(function (response) {
                    if (response.data.status === 200) {
                        var city_list = response.data.data.map(elm => ({ id: elm.id, label: elm.city_name, value: elm.id, state_id: elm.state_id }));
                        setCityList(city_list);
                        if (setData) {
                            let city_data = city_list.filter(e => mapRegion.city.includes(e.id))
                            if (city_data && city_data.length > 0) {
                                let currval = city_data.map(v => v.id);
                                temp_role_details["city"] = currval
                                setCityData(city_data)
                            } else {
                                temp_role_details["city"] = []
                                setCityData([])
                            }
                        }
                    }
                })
                .catch(function (response) {
                });
        }
    }

    const handleClickNext = async () => {
        if (isValid()) {
            handleClick();
        }
    }

    const handleClick = async () => {
        await props.handleSave(roleDetailsInfo, 'role_details_info')
        if (isEdit) {
            historyLead(`assign_rights?id=${roleDetails.role_id}`);
        } else {
            historyLead('assign_rights')
        }

    }

    const handleChange = (name, selectOpt) => {
        let tempRoleDetailsInfo = { ...roleDetailsInfo }
        let tempError = { ...errors }
        if (name === 'vertical') {
            tempRoleDetailsInfo[name] = selectOpt.id;
            setRoleDetailsInfo(tempRoleDetailsInfo);
        } else if (name === 'business_unit') {
            tempRoleDetailsInfo[name] = selectOpt.id;
            setRoleDetailsInfo(tempRoleDetailsInfo);
        }
        if (isEdit) {
            roleDetailsInfo['role_id'] = roleDetails.id;
        }

        if (selectOpt.id !== '') {
            tempError[name] = '';
        }
        setErrors(tempError);
    }

    const handleSelectChange = (name, selectOpt) => {
        let tempRoleDetailsInfo = { ...roleDetailsInfo }
        let tempError = { ...errors }
        if (name === 'status') {
            tempRoleDetailsInfo[name] = selectOpt.value;
            tempError[name] = '';
            setCurrentStatus([selectOpt]);
            setRoleDetailsInfo(tempRoleDetailsInfo)
        }
        setErrors(tempError);
    }

    const handleInputChange = (target_name, type, event) => {
        let tempRoleDetailsInfo = { ...roleDetailsInfo };
        let tempErrors = { ...errors };
        if (target_name === 'role_name') {
            tempRoleDetailsInfo[target_name] = event.target.value;
            tempErrors[target_name] = '';
            setRoleDetailsInfo(tempRoleDetailsInfo);
        }
        if (target_name === 'role_description') {
            tempRoleDetailsInfo[target_name] = event.target.value;
            tempErrors[target_name] = '';
            setRoleDetailsInfo(tempRoleDetailsInfo);
        }
        if (type === 'checkbox') {
            if (event.target.checked) {
                tempRoleDetailsInfo[target_name] = '1';
            } else {
                tempRoleDetailsInfo[target_name] = '0';
            }
            setRoleDetailsInfo(tempRoleDetailsInfo);
        }
        setErrors(tempErrors)
    }

    const handleChangeMultiSel = (fieldName, fieldObj, event) => {
        let currVal = [];
        let selectedVal = [];
        let tempRoleDetailsInfo = { ...roleDetailsInfo }
        if (event.action === 'select-option' && event.option.value === "*") {
            selectedVal = fieldName === 'state' ? [{ label: "Select All", value: "*" }, ...stateList] : [{ label: "Select All", value: "*" }, ...cityList];
            currVal = fieldName === 'state' ? stateList.map(v => v.id) : cityList.map(v => v.id);
        } else if (event.action === 'deselect-option' && event.option.value === "*") {
            selectedVal = []
        } else if (event.action === 'deselect-option' && event.option.value !== "*") {
            selectedVal = fieldObj.filter((o) => o.value !== "*");
            currVal = selectedVal.map(v => v.id);
        } else if (fieldName === 'state' && fieldObj.length === stateList.length) {
            currVal = stateList.map(v => v.id);
            selectedVal = [{ label: "Select All", value: "*" }, ...stateList]
        } else if (fieldName === 'city' && fieldObj.length === cityList.length) {
            currVal = cityList.map(v => v.id);
            selectedVal = [{ label: "Select All", value: "*" }, ...cityList];
        } else {
            currVal = fieldObj.map(v => v.id);
            selectedVal = fieldObj
        }
        tempRoleDetailsInfo[fieldName] = currVal
        if (fieldName === 'state') {
            errors['state'] = '';
            setStateData(selectedVal);
            getCityList(currVal, true, tempRoleDetailsInfo);
        }
        if (fieldName === 'city') {
            errors['city'] = '';
            setCityData(selectedVal);
        }
        setRoleDetailsInfo(tempRoleDetailsInfo);
    }

    const isValid = () => {
        let tempRoleDetailsInfo = { ...roleDetailsInfo }
        let tempErrors = { ...errors }
        let isFormValid = true
        if (!tempRoleDetailsInfo['vertical']) {
            isFormValid = false;
            tempErrors['vertical'] = 'Vertical is required'
        }
        if (!tempRoleDetailsInfo['business_unit']) {
            isFormValid = false;
            tempErrors['business_unit'] = 'Business Unit is required'
        }
        if (!tempRoleDetailsInfo['state']) {
            isFormValid = false;
            tempErrors['state'] = 'State is required'
        }
        if (!tempRoleDetailsInfo['city']) {
            isFormValid = false
            tempErrors['city'] = 'City is required'
        }
         if (!tempRoleDetailsInfo['role_name']) {
             isFormValid = false;
             tempErrors['role_name'] = 'Role Name is required'
         }
        if (!tempRoleDetailsInfo['role_description']) {
            isFormValid = false;
            tempErrors['role_description'] = 'Role Description is requried'
        }
        if (!tempRoleDetailsInfo['status']) {
            isFormValid = false;
            tempErrors['status'] = 'Status is required'
        }
        setErrors(tempErrors);
        return isFormValid;
    }

    return <React.Fragment>
        <div className='role-details-form p-xxl add-user-detail-filed'>
            <h2>Role Details</h2>
            <h3> Business Vertical </h3>
            <div className='row'>
                <fieldset className='single-select col-md-4'>
                    <div className='material'>
                        <Select
                            components={{ ValueContainer: CustomValueContainer }}
                            options={Business}
                            placeholder="Select Vertical"
                            className='react-select'
                            classNamePrefix="react-select"
                            onChange={handleChange.bind(this, 'vertical')}
                            value={Business.filter(e => e.id === roleDetailsInfo.vertical)}
                        />
                    </div>
                    {(errors && errors.vertical) ? <span className="error show">{errors.vertical}</span> : ''}
                </fieldset>

                <fieldset className='single-select col-md-4'>
                    <div className='material'>
                        <Select
                            components={{ ValueContainer: CustomValueContainer }}
                            options={Vertical}
                            placeholder="Select Business Unit"
                            className='react-select'
                            classNamePrefix="react-select"
                            onChange={handleChange.bind(this, 'business_unit')}
                            value={Vertical.filter(e => e.id === roleDetailsInfo.business_unit)}
                        />
                    </div>
                    {(errors && errors.business_unit) ? <span className="error show">{errors.business_unit}</span> : ''}
                </fieldset>
            </div>

            <h3>Role Information</h3>
            <div className='row align-center'>
               <fieldset className='single-select col-md-4'>
                    <div className='material'>
                        <InputField 
                            type='text' 
                            name='role_name' 
                            id='role_name' 
                            label="Role Name" 
                            placeholder=" " 
                            required={true} 
                            value={roleDetailsInfo.role_name}
                            error={errors.role_name ? errors.role_name : ''}
                            onChange={handleInputChange.bind(null, 'role_name', 'text')}
                        />
                        <label data-label="Role Name*" className="form-label"></label>
                    </div>
                </fieldset>

                <fieldset className='single-select col-md-4'>
                    <div className='material'>
                        <Select
                            components={{ ValueContainer: CustomValueContainer }}
                            options={status}
                            value={status.filter(e => e.value === roleDetailsInfo.status)}
                            placeholder="Select Status"
                            className="react-select"
                            classNamePrefix="react-select"
                            onChange={handleSelectChange.bind(this, 'status')}
                        />
                    </div>
                    {(errors && errors.status) ? <span className="error show">{errors.status}</span> : ''}
                </fieldset>

                <fieldset className="col-md-4">
                    <div className="custom-control custom-checkbox">
                        <input
                            id="digital_consultant"
                            type="checkbox"
                            className="custom-control-input"
                            onChange={handleInputChange.bind(null, 'digital_consultant', 'checkbox')}
                            checked={roleDetailsInfo.digital_consultant === '1' ? 'checked' : null}
                        />
                        <label htmlFor="digital_consultant" className="custom-control-label">
                            Digital Consultant
                        </label>
                    </div>
                </fieldset>

                <fieldset className="col-md-12">
                    <div className="material">
                        <textarea
                            placeholder=" "
                            className="form-input"
                            rows="3"
                            name="comment"
                            value={roleDetailsInfo.role_description}
                            required={true}
                            onChange={handleInputChange.bind(null, 'role_description', 'textarea')}
                        ></textarea>
                        <label data-label="Role Discription" className="form-label"></label>
                    </div>
                    {(errors && errors.role_description) ? <span className="error show">{errors.role_description}</span> : ''}
                </fieldset>
            </div>

            <h3> Map Region </h3>
            <div className='row'>
                <fieldset className="single-select col-md-4">
                    <div className="material">
                        <Select
                            components={{ ValueContainer: CustomValueContainer }}
                            options={Zone}
                            placeholder="Select Zone"
                            className="react-select"
                            classNamePrefix="react-select"
                            value={Zone.filter(e => {
                                if (Array.isArray(roleDetailsInfo.zone_id)) {
                                    return roleDetailsInfo.zone_id.filter(z => z.id === e.id);
                                } else {
                                    if (e.id === roleDetailsInfo.zone_id) {
                                        return true;
                                    } else {
                                        return false;
                                    }
                                }
                            })}
                        />
                    </div>
                </fieldset>

                <fieldset className="multiselect-dropDown col-md-4">
                    <div className="material">
                        <MultiSelect
                            isMulti
                            options={[{ label: "Select All", value: "*" }, ...stateList]}
                            placeholderButtonLabel="State*"
                            iconAfter="false"
                            getDropdownButtonLabel={getDropdownButtonLabel}
                            value={stateData?.length === stateList?.length ? [{ label: "Select All", value: "*" }, ...stateList] : stateData}
                            onChange={handleChangeMultiSel.bind(this, "state")}
                            getOptionLabel={({ label }) => label}
                            getOptionValue={({ id }) => id}
                        />
                        {(stateData && stateData.length > 0) ? <label data-label="State*" className="form-label date-label"></label> : ''}
                    </div>
                    {(errors && errors.state) ? <span className="error show absolute">{errors.state}</span> : ''}
                </fieldset>

                <fieldset className="multiselect-dropDown col-md-4">
                    <div className="material">
                        <MultiSelect
                            isMulti
                            options={[{ label: "Select All", value: "*" }, ...cityList]}
                            placeholderButtonLabel="City*"
                            iconAfter="false"
                            getDropdownButtonLabel={getDropdownButtonLabel}
                            value={cityData?.length === cityList?.length ? [{ label: "Select All", value: "*" }, ...cityList] : cityData}
                            onChange={handleChangeMultiSel.bind(this, 'city')}
                            getOptionLabel={({ label }) => label}
                            getOptionValue={({ id }) => id}
                        />
                     {(cityData && cityData.length > 0) ? <label data-label="City*" className="form-label date-label"></label> : ''}
                    </div>
                    {(errors && errors.city) ? <span className="error show absolute">{errors.city}</span> : ''}
                </fieldset>
            </div>

            <div className="br-top t-right">
                <button className='btn-primary' onClick={handleClickNext}>
                    Save & Continue
                </button>
            </div>
        </div>
    </React.Fragment>
}

const CustomValueContainer = ({ children, ...props }) => {
    return (
        <ValueContainer {...props}>
            <Placeholder {...props} isFocused={props.isFocused}>
                {props.selectProps.placeholder}
            </Placeholder>
            {React.Children.map(children, child =>
                child && child.type !== Placeholder ? child : null
            )}
        </ValueContainer>
    );
};

export default AddRoleDetailsComponent;