import React, { Component } from 'react';
import { connect } from 'react-redux';
import secureStorage from './../../config/encrypt';
import ls from "local-storage";
import { Navigate } from "react-router-dom";

class Logout extends Component{
    componentDidMount() {
        ls.set('logged_user_id',0);
        ls.set('logged_data',{});        
        secureStorage.removeItem('loginUserInfo');
        this.props.updateStateIntoRedux('LOGOUT_USER', '');  
        //this.props.history.push("/login")
    }
    render(){       
        return <Navigate to="/login" />
    }
}

const mapDispatchToProps = dispatch => {
    return {
        updateStateIntoRedux: (actionType, stateData) => dispatch({type:actionType, componentState: stateData})
    }
  }

export default connect(null, mapDispatchToProps)(Logout);