import React, { Component } from 'react';
import { BrowserRouter, NavLink } from 'react-router-dom';

import Selfie_SSS_ID from "../../webroot/images/SelfieSSS_ID.png";
import SSS_ID from "../../webroot/images/SSS_ID.png";
import Bank_Statement from "../../webroot/images/BankStatement.png";
import NPWP from "../../webroot/images/NPWP.png";
import Agreement_Form from "../../webroot/images/AgreementForm.png";
import SimpleSlider from "./uploadDocSlider"



class DocumentUplaodPreview extends Component {
    constructor(props) {
        super(props);
        //console.log(props)

        this.state = {
            AddPrefrencepopup: false,

        };
    }

    showModalAddPrefrencepopup = () => {
        this.setState({ AddPrefrencepopup: true });
        document.body.classList.add("overflow-hidden");
    }

    hideModalAddPrefrencepopup = () => {
        this.setState({ AddPrefrencepopup: false });
        document.body.classList.remove("overflow-hidden");
    }

    render() {

        return (
            <React.Fragment>
                <div className='add-user-outer'>
                    <BrowserRouter>
                        <div className="add-user-form-sec">
                            <div className="menu-tab-left-panel">
                                <div className="add-user-left-panel nav">
                                    <ul>
                                        <li>
                                            <NavLink to="/RoleDetails" className="completed active" aria-current="page" exact>
                                                <span className="img-type"></span>
                                                Role Details
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/BasicDetails" className="">
                                                <span className="img-type"></span>
                                                Basic Details
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/LeadDetailsForm" className="">
                                                <span className="img-type"></span>
                                                Documents
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/LeadDetailsForm" className="">
                                                <span className="img-type"></span>
                                                Preview and Confirm
                                            </NavLink>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="route-files">
                                <div className='document-details-form p-xxl add-user-detail-filed'>
                                    <h2>Documents</h2>

                                    <div className='upload-document-outer'>
                                        <div className="img-bx-select-opt" onClick={this.showModalAddPrefrencepopup}>
                                            <div className="image-bx">
                                                <img alt ='' src={Selfie_SSS_ID} className="" />
                                            </div>
                                            <span className='document-name'>Selfie with SSS ID</span>

                                        </div>
                                        <div className="img-bx-select-opt">
                                            <div className="image-bx">
                                                <img alt='' src={SSS_ID} className="" />
                                            </div>
                                            <span className='document-name'>SSS ID</span>

                                        </div>
                                        <div className="img-bx-select-opt">
                                            <div className="image-bx">
                                                <img alt='' src={Bank_Statement} className="" />
                                            </div>
                                            <span className='document-name'>Bank Statement</span>

                                        </div>
                                        <div className="img-bx-select-opt">
                                            <div className="image-bx">
                                                <img alt='' src={NPWP} className="" />
                                            </div>
                                            <span className='document-name'>NPWP</span>

                                        </div>
                                        <div className="img-bx-select-opt">
                                            <div className="image-bx">
                                                <img alt='' src={Agreement_Form} className="" />
                                            </div>
                                            <span className='document-name'>Agreement Form</span>

                                        </div>

                                    </div>
                                    <div className="br-top t-right">
                                        <button className='btn-primary'>
                                        Upload & Continue
                                        </button>
                                    </div>

                                </div>


                            </div>
                        </div>
                    </BrowserRouter>

                    <div className="vechile-document-popup model-popup-outer">
                        <Modal show={this.state.AddPrefrencepopup} handleClose={this.hideModalAddPrefrencepopup} >
                            <div className="modal-header">
                                <h2>Selfie with KPT</h2>
                            </div>
                            <div className="modal-body">
                                <SimpleSlider />
                            </div>
                            <div className='modal-footer'>
                                <div className="image-opt">
                                    <ul>
                                        <li>
                                            <a href="/#">
                                                <i className="ic-delete-icn"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="/#">
                                                <i className="ic-refresh"></i>
                                            </a>
                                        </li>

                                        {/* <li>
                                            <a href="/#">
                                                <i className="ic-fullscreen"></i>
                                            </a>
                                        </li> */}

                                        <li>
                                            <a href="/#">
                                                <i className="ic-add"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="/#">
                                                <i className="ic-remove"></i>
                                            </a>
                                        </li>


                                    </ul>

                                    <button className="btn-line btn-re-upload">Re-upload</button>

                                </div>
                            </div>
                        </Modal>
                    </div>
                </div>

            </React.Fragment>
        )
    }
}

const Modal = ({ handleClose, show, children }) => {
    const showHideClassName = show ? 'modal display-block' : 'modal display-none';

    return (
        <div className={showHideClassName}>
            <section className='modal-main'>
                {children}
                <button
                    onClick={handleClose}
                    className="close_icn"
                >
                    <i className="ic-clearclose"></i>
                </button>
            </section>
        </div>
    );
};
export default DocumentUplaodPreview;