import React from 'react';
class InputField extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        active: (props.locked && props.active) || false,
        value: props.value || "",
        id: props.id || "",
        name: props.name || "",
        type: props.type || "type",
        error: props.error || "",
        label: props.label || "",
        maxLength:props.maxLength || "",
        required:props.required || false
      };
    }
  
    changeValue = (event) => {
      let { value } = event.target;
      if (/^[0-9]+$/.test(this.props.min)) value = Math.max(parseFloat(this.props.min), parseFloat(value));
      if (/^[0-9]+$/.test(this.props.max)) value = Math.min(parseFloat(this.props.max), parseFloat(value));
        event.target.value = value;
        this.setState({ value, error: "" });
        if (typeof this.props.onChange === 'function') {
          this.props.onChange(event);
        }
      
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ 
            error: nextProps.error,
            required: nextProps.required,
            value: nextProps.value
        });
    }
  
    onKeyPress = (event) => {
      // console.log('onKeyPress() > event', event);
      this.props.onKeyPress && this.props.onKeyPress(event)
      if (event.which === 13) {
        // console.log('onKeyPress() > event > ENTER CALL');
        // this.setState({ value: this.props.predicted });

      }
    }
  
    render() {
      const { active, value, error, id, type, name,maxLength } = this.state;
      const { predicted, locked } = this.props;
      let fieldClassName = `form-field ${(locked ? active : active || value) &&
        "active"} ${locked && !active && "locked"}`;
      if(error) fieldClassName += ` field-error`
      return (
        <>
          {active &&
            value &&
            predicted &&
            predicted.includes(value) && <p className="predicted">{predicted}</p>}
            {/* <label htmlFor={id}>{label}</label> */}
            {/* <label htmlFor={id}>{label} {required && <span className="required" style={{ color: 'red' }}>*</span>}</label> */}
          <input
            id={id}
            className="form-input"
            type={type}
            value={value || ""}
            name={name}
            placeholder={this.props.placeholder}
            onChange={this.changeValue}
            disabled={this.props.disabled}
            onKeyPress={this.onKeyPress}
            //onFocus={() => !locked && this.setState({ active: true })}
            onFocus={this.props.onFocus}
            // onBlur={() => !locked && this.setState({ active: false })}
            maxLength={maxLength}
            autoComplete="off"
            readOnly={this.props.readOnly}
          />          
          {error && <span className="error show">{error}</span>}
          </>
      );
    }
  }
  
 
 
export default InputField;

