import React, { useEffect, useState } from "react";
import { Tab } from 'react-bootstrap';
import AttendanceReportTopFilter from "./AttendanceReportTopFilter"
import { MasterService } from "../../services";
import Loader from "../common/loader";
import AttendancelistingTable from './AttendanceListingTable';
import { getFirstDate } from '../../config/helper';
const AttendanceReport = (props) => {
    const [loading, setLoading] = useState(false);
    const [pageCount, setPageCount] = useState(0)
    // const [searchData, setSearchData] = useState({})
    const [isPageReset, setPageReset] = useState(0);
    const [isReset, setReset] = useState(0);
    const [attendance_search_params, setAttendanceSearchParams] = useState({})
    const [attendanceData, setAttendanceData] = useState([]);
    // const [attendanceCount,setAttendanceCount] = useState(null);

    useEffect(() => {
        let params = {
            start_date: getFirstDate(new Date()),
            end_date: new Date(),
            attendance_status_filter:[
                "P",
                "A",
                "L"
            ],
            page: 1,
        }
        getAttendanceData(1, params, true)
    }, [])


    const getAttendanceData = async (page_no, params, page_reset) => {
        let result = []
        if(page_reset){
            setPageReset(isPageReset+1)
        }
        let temp_search_params = { ...attendance_search_params }
        if (Object.keys(params).length) {
            setAttendanceSearchParams(params)
            temp_search_params = params
        } else {
            temp_search_params.page = page_no
        }
        setLoading(true)
        await MasterService.post('/sfa/attendance/get-attendance-report', temp_search_params).then(function (response) {
            if (response && response.data && response.data.status === 200 &&  response.data.data && response.data.data.attendance_data) {
                let tempPageCount = Math.ceil(response.data.data.allPackageListcount / response.data.data.per_page);
                setAttendanceData(response.data.data.attendance_data)
                result = response.data.data.attendance_data;
                setPageCount(tempPageCount);
            }else{
                setAttendanceData([])
                setPageCount(0);
                // setAttendanceCount(0)
                setLoading(false)
            }
            setLoading(false);
        }).catch(function (response) {
            setLoading(false)
        });
        return result;
    }

    const getExportData = async (params) => {
        let postData = attendance_search_params;
        let result = []
        setLoading(true)
        await MasterService.post('/sfa/attendance/export-attendance-report', postData)
            .then(function (response) {
                if (response && response.data && response.data.status === 200 &&  response.data.data && response.data.data.attendance_row_data) {
                    result = response.data.data.attendance_row_data;
                }
            })
            .catch(function (response) {
            });
            setLoading(false)
        return result
    }

    return (
        <React.Fragment>
            {loading ? <Loader /> : null}
            <div className="container-fluid">
                <div className="dealer-user-mapping-user">
                    <div className="top-heading d-flex">
                        <h1>Attendance Report</h1>
                    </div>
                    <div className='visit-filter-outer'>
                        <AttendanceReportTopFilter isReset={isReset} getAttendanceData={getAttendanceData} />
                    </div>
                    <div className="dealer-user-mapping-tab-outer no-visit-report-outer">
                        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                            <Tab.Content>
                                <Tab.Pane eventKey="first">
                                    <AttendancelistingTable
                                        getAttendanceData={getAttendanceData}
                                        pageCount={pageCount}
                                        isPageReset={isPageReset}
                                        attendanceData={attendanceData}
                                        loading={loading}
                                        getExportData={getExportData}
                                    />
                                </Tab.Pane>
                            </Tab.Content>
                        </Tab.Container>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
export default AttendanceReport;