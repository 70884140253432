import UserManagement from "../view/manage-user/UserManagement"
import UserManagementDetail from "../view/manage-user/UserManagementDetails"
import AddUser from "../view/manage-user/AddUser"
import EditUser from "../view/manage-user/EditUser"
import BasicDetailUpdated from "../view/manage-user/BasicDetailUpdated"
import DocumentUplaodPreview from "../view/manage-user/DocumentUplaodPreview"
import ViewUser from "../view/manage-user/ViewUser"
import AgentDetails from "../view/manage-user/AgentDetail"
import ViewUserProfile from "../view/manage-user/ViewUserProfile"
import UserLogin from "../view/user-login/UserLogin"
//import Login from "../view/user-login/Login"
import RoleManagement from "../view/manage-role/RoleManagement"
import AddRole from "../view/manage-role/AddRole"
import RoleUserDetail from "../view/manage-role/RoleUserDetail"
import EditRole from "../view/manage-role/EditRole.js"
// import esign_agreement_log_view from "../view/user-login/esign_agreement_log_view"
//import logout from "../view/user-login/logout"
// import progressionList from "../view/progression/progressionList"
import DealerUserListing from "../view/dealer-user-listing/DealerUserListing"
import VisitReport from "../view/view-report/VisitReport"
import MapDealerManagement from "../view/map-dealers/MapDealer"
import Logout from "../view/user-login/logout"
import Login from "../view/login/Login"
import Register from "../view/login/Register"
import ESIGNLOGVIEW from "../view/esign/EsignView"
import OfficeAddress from "../view/admin/OfficeAddress"
import EditOfficeAddress from "../view/admin/EditOfficeAddress"
import ResetMaxAttempts from "../view/admin/ResetMaxAttempts"
import NoVisitReport from "../view/no-view-report/NoVisitReport"
import AttendanceReport from "../view/attendance-report/AttendanceReport"
import UserMappingHistory from "../view/user-mapped-history/UserMappingHistory";
import BMTarget           from "../view/bm-target/BMTarget.js";

import { useRoutes } from "react-router-dom";

function AppRoutes() {
	let Router = useRoutes([ 
		{ path: "/", exact: true, element: <UserManagement/> },
		{ path: "/user-management",exact: true, element: <UserManagement/> },	
		{ path: "/user-management-detail", element: <UserManagementDetail/> },
		{ path: "/add-user", element: <AddUser/> },
		{ path: "/add-user/:pagename", element: <AddUser/> },
		{ path: "/basic-detail-updated", element: <BasicDetailUpdated/> },
		{ path: "/document-preview", element: <DocumentUplaodPreview/> },
		{ path: "/agent-detail", element: <AgentDetails/> },
		{ path: "/view-user/:user_id", element: <ViewUser/> },
		{ path: "/edit-user/:user_id", element: <EditUser/> },
		{ path: "/view-profile/:user_id", element: <ViewUserProfile/> },
		{ path: "/user-login",exact: true, element: <UserLogin/> },
		{path : "/role-management", element : <RoleManagement/>},
		{path : "/add-role", element : <AddRole/>}, 	
		{ path: "/progression-config", element:<progressionList/> },
		{ path: "/dealer-user-listing", element: <DealerUserListing/> },
		{ path: "/user/esign_agreement_log_view", element: <ESIGNLOGVIEW/>},
		{path : "/view-role/:role_id", element : <RoleUserDetail/>},
		{path : '/edit-role/:role_id', element : <EditRole/>},	
		{ path: "/map-dealer", element: <MapDealerManagement/> },
		{ path: "/logout", element:<Logout/> },
		{ path: "/login", element: <Login/> },
		{ path: "/register", element: <Register/> },
		{ path: "/view-report", element: <VisitReport/> },
		{ path: "/office_address", element: <OfficeAddress/> },
		{ path: "/edit_office_address", element: <EditOfficeAddress/> },
		{ path: "/reset_max_attempts", element: <ResetMaxAttempts/> },
		{ path: "/no-view-report", element: <NoVisitReport/> },
		{ path: "/attendence-report", element: <AttendanceReport/> },
		{ path: "/user-mapping-history/:user_id", element: <UserMappingHistory/> },
		{ path: "/target", element: <BMTarget/> }
	  ]);
	return Router;
}


export default AppRoutes;
