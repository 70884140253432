import React, { useRef } from "react";
import Slider from "react-slick";
import NoImage from "../../webroot/images/noimage.svg";

const SimpleSlider = (props) => {
    let { doc, image_width, rotated_angle, activeIndex, errors, ConfigList } = props
    const slider = useRef(null)
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        afterChange: current => handleSlideChange(current)
    };
    if (slider.current) {
        if (typeof slider.current.slickGoTo === "function") {
            slider.current.slickGoTo(activeIndex)
        }
    }

    const handleSlideChange = (cur) => {
        if (cur !== activeIndex)
            props.changeSlide(cur)
    }
    return (
        <div>
            <Slider ref={slider} {...settings}>
                {ConfigList && ConfigList.document && doc.length > 0 ? doc.map((data, index) => (
                    <div className="slider_image_text" key={index}>
                        <h2>{ConfigList.document.filter(e => parseInt(e.id) === parseInt(data.document_id))[0].doc_title}</h2>
                        <img src={data.doc_url ? data.doc_url : NoImage} className="" alt='No Document found' style={{ width: image_width + "px", transform: "rotate(" + rotated_angle + "deg)" }} />
                        <br/>
                        {
                            (errors && errors[ConfigList.document.filter(e => parseInt(e.id) === parseInt(data.document_id))[0].doc_title]) ? <span className="error show">{errors[ConfigList.document.filter(e => parseInt(e.id) === parseInt(data.document_id))[0].doc_title]}</span> : ''
                        }
                    </div>
                )) :
                    <div>
                        <label>No Document</label>
                    </div>
                }
            </Slider>

        </div>
    );
}

export default SimpleSlider;