import React, { useState, useEffect } from 'react';
import { Role } from '../../config/constant';


const HierarchyDetails = (props) => {

    const [managersData,setManagersData] = useState({});


    useEffect(()=>{
        let tempManagersData = {...managersData};
        if (props.allManagerList && props.allManagerList.length) {                    
            for (let data of props.allManagerList) {
                if(data.role_id === Role['National Head']){
                    tempManagersData['National Head']={
                        id: data.id,
                        name: data.name,
                    }
                }
                if(data.role_id === Role['Regional Head']){
                    tempManagersData['Regional Head']={
                        id: data.id,
                        name: data.name,
                    }
                }
                if(data.role_id === Role['Business Manager']){
                    tempManagersData['Business Manager']={
                        id: data.id,
                        name: data.name,
                    }
                }
                if(data.role_id === Role['City Business Manager']){
                  tempManagersData['City Business Manager']={
                      id: data.id,
                      name: data.name,
                  }
                }
                if(data.role_id === Role['Sales Head']){
                  tempManagersData['Sales Head']={
                      id: data.id,
                      name: data.name,
                  }
                }
                if(data.role_id === Role['Consulting Business Manager']){
                  tempManagersData['Consulting Business Manager']={
                      id: data.id,
                      name: data.name,
                  }
                }
                if(data.role_id === Role['Area Business Manager']){
                  tempManagersData['Area Business Manager']={
                      id: data.id,
                      name: data.name,
                  }
                }  
                if(data.role_id === Role['Zonal Head']){
                  tempManagersData['Zonal Head']={
                      id: data.id,
                      name: data.name,
                  }
                }                                                                    
            }
        }

        setManagersData(tempManagersData);
    },[props.allManagerList])



    return (
      <div className="reporting-line-detail">
        <div className="modal-header">
          <h2>Reporting Line</h2>
          <span>
            {"User Details"}
          </span>
        </div>
        {props.allManagerList ? (
          <div className='modal-body'>
            {managersData['Consulting Business Manager']?.id ? <> <div className="detail">
              <div>{"Consulting Business Manager Name"}</div>
              <div>{managersData['Consulting Business Manager']?.name || "-"}</div>
            </div>
            <div className="detail">
              <div>{"Consulting Business Manager ID"}</div>
              <div>{managersData['Consulting Business Manager']?.id || "-"}</div>
            </div></> : null}            
            {managersData['Business Manager']?.id ? <> <div className="detail">
              <div>{"Business Manager Name"}</div>
              <div>{managersData['Business Manager']?.name || "-"}</div>
            </div>
            <div className="detail">
              <div>{"Business Manager ID"}</div>
              <div>{managersData['Business Manager']?.id || "-"}</div>
            </div></> : null}
            {managersData['Area Business Manager']?.id ? <> <div className="detail">
              <div>{"Area Business Manager Name"}</div>
              <div>{managersData['Area Business Manager']?.name || "-"}</div>
            </div>
            <div className="detail">
              <div>{"Area Business Manager ID"}</div>
              <div>{managersData['Area Business Manager']?.id || "-"}</div>
            </div></> : null}
            {managersData['City Business Manager']?.id ? <> <div className="detail">
              <div>{"City Business Manager Name"}</div>
              <div>{managersData['City Business Manager']?.name || "-"}</div>
            </div>
            <div className="detail">
              <div>{"City Business Manager ID"}</div>
              <div>{managersData['City Business Manager']?.id || "-"}</div>
            </div></> : null}
            {managersData['Regional Head']?.id ? <> <div className="detail">
              <div>{"Regional Head Name"}</div>
              <div>{managersData['Regional Head']?.name || "-"}</div>
            </div>
            <div className="detail">
              <div>{"Regional Head ID"}</div>
              <div>{managersData['Regional Head']?.id || "-"}</div>
            </div></> : null}
            {managersData['Zonal Head']?.id ? <> <div className="detail">
              <div>{"Zonal Head Name"}</div>
              <div>{managersData['Zonal Head']?.name || "-"}</div>
            </div>
            <div className="detail">
              <div>{"Zonal Head ID"}</div>
              <div>{managersData['Zonal Head']?.id || "-"}</div>
            </div></> : null}
            {managersData['National Head']?.id ? <> <div className="detail">
              <div>{"National Head Name"}</div>
              <div>{managersData['National Head']?.name || "-"}</div>
            </div>
            <div className="detail">
              <div>{"National Head ID"}</div>
              <div>{managersData['National Head']?.id || "-"}</div>
            </div></> : null}
            {managersData['Sales Head']?.id ? <> <div className="detail">
              <div>{"Sales Head Name"}</div>
              <div>{managersData['Sales Head']?.name || "-"}</div>
            </div>
            <div className="detail">
              <div>{"Sales Head ID"}</div>
              <div>{managersData['Sales Head']?.id || "-"}</div>
            </div></> : null}
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }

export default HierarchyDetails;