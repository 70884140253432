import React from "react";
import DatePicker from "react-datepicker";
import {  getbackDateInDays } from '../../config/helper';
import "react-datepicker/dist/react-datepicker.css";
const Calendra = (props) => {
    // const [dateRange, setDateRange] = useState([null, null]);
    // const [startDate, endDate] = dateRange;
    // const setDateRange = (e) => {
    //     console.log("e", e)
    // }
    return (
        <div className="datepicker">
            <DatePicker
                // selectsRange={true}
                // startDate={startDate}
                dateFormat="yyyy-MM-dd"
                maxDate={props.endDate}
                minDate={getbackDateInDays(90)}
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                onChange={(e) => {
                    props.handleDateChange(e)
                }}
                selected={props.defaultDate}
                isClearable={false}
                placeholderText={props.placeholder}
            />
        </div>
    );
}
const Calendra2 = (props) => {
    // const [dateRange, setDateRange] = useState([null, null]);
    // const [startDate, endDate] = dateRange;
    // const setDateRange = (e) => {
    //     console.log("e", e)
    // }
    return (
        <div className="datepicker">
            <DatePicker
                // selectsRange={true}
                // startDate={startDate}
                dateFormat="yyyy-MM-dd"
                minDate={getbackDateInDays(90)}
                maxDate={props.endDate}
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                onChange={(e) => {
                    props.handleDateChange(e)
                }}
                selected={props.defaultDate}
                isClearable={false}
                placeholderText={props.placeholder}
            />
        </div>
    );
}
export { Calendra, Calendra2 }