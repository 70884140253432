import React, { useState, useEffect } from 'react';
import Select, { components } from 'react-select';
import MasterService from './../../services/MasterService';
import { Role, MANAGER_ROLES } from './../../config/constant';
import { toast } from 'react-toastify';
import Loader from '../common/loader';
// import secureStorage from '../../config/encrypt';
const { ValueContainer, Placeholder } = components;

const CustomValueContainer = ({ children, ...props }) => {
    return (
      <ValueContainer {...props}>
        <Placeholder {...props} isFocused={props.isFocused}>
          {props.selectProps.placeholder}
        </Placeholder>
        {React.Children.map(children, child =>
          child && child.type !== Placeholder ? child : null
        )}
      </ValueContainer>
    );
  };

const ChangeMappingPopUp = (props) => {
    const [reportingRole, setReportingRole] = useState([]);
    const [reportingTo, setReportingTo] = useState([]);
    const [errors, setErrors] = useState({});
    const [reportingRoleId, setReportingRoleId] = useState(0);
    const [reportingUserId, setReportingUserId] = useState(0);
    const [errorList, setErrorList] = useState([]);
    const [loading, setLoading] =  useState(false);
    const [mappingList, setMappingList] = useState([]);
    const [currentUserRoleId, setCurrentUserRoleId] = useState(undefined);
  
    useEffect(()=>{
      setReportingRole(props.ReportingRole || []);
    },[props.ReportingRole])
  
    useEffect(()=>{
      let tempMappingList = [...mappingList];
      if(props?.userList?.user_list?.length){
        tempMappingList = props.userList.user_list.filter(ele => ele.is_selected);
        let tempCurrentUserRoleId = tempMappingList[0]?.role_id;
        setCurrentUserRoleId(tempCurrentUserRoleId);
        tempMappingList = tempMappingList.map((ele)=>{
          return {
            user_id:ele.userId,
            manager_id:reportingUserId,
            manager_role_id:reportingRoleId
          }
        })
      }
      setMappingList(tempMappingList);
    },[props.userList,reportingUserId,reportingRoleId])
  
    // useEffect(()=>{
    //   if(props.currentChangeManagerRole){
    //     setReportingRoleId(Role[props.currentChangeManagerRole]);
    //     getRoleUsersList(Role[props.currentChangeManagerRole]);
    //   }
    // },[props.currentChangeManagerRole])
  
    useEffect(()=>{
      if(props.currentChangeManagerRole){
        setReportingRoleId(props.currentChangeManagerRole?.manager_role_id);
        getRoleUsersList(props.currentChangeManagerRole?.manager_role_id);
      }
    },[props.currentChangeManagerRole])
  
    useEffect(()=>{
      if(currentUserRoleId){
        let tempReportingRole = [...reportingRole];
        let managerLevel = MANAGER_ROLES.find(ele => ele.id === currentUserRoleId);
        if(managerLevel){
          let listOfManagersToShow = MANAGER_ROLES.filter(ele => ele.rank < managerLevel.rank).map(ele => ele.id);
          tempReportingRole = tempReportingRole.filter(ele => listOfManagersToShow.includes(ele.id));
        }
        setReportingRole(tempReportingRole);
      }  
    },[currentUserRoleId])
  
    const handleReportingRoleChange = (e) => {
      setReportingRoleId(e.id);
      getRoleUsersList(e.id);
      setErrorList([]);
      let tempErrors = {errors};
      delete tempErrors['reporting_to_role']
      setErrors(tempErrors);
    }
    const handleReportingToChange = (e) => {
      setReportingUserId(e.id);
      setErrorList([]);
      let tempErrors = {errors};
      delete tempErrors['reporting_to']
      setErrors(tempErrors);
    }
  
    const getRoleUsersList = (role_id) => {
      setLoading(true);
      MasterService.post('/sfa/user/get-users', { 'role_id': role_id })
        .then(function (response) {
          if (response.data.status === 200) {
            setReportingTo(response.data.data.user_list);
            setLoading(false);
          }else{
            setLoading(false);
          }
        })
        .catch(function (response) {
          setLoading(false);
        });
    }
  
    const validateForm =()=>{
      let result = true;
      let tempErrors = {errors};
      if(!reportingRoleId){
        tempErrors['reporting_to_role'] = 'Please select Reporting To Role';
        result = false;
      }
  
      if(!reportingUserId){
        tempErrors['reporting_to'] = 'Please select Reporting To user';
        result = false;
      }
  
      setErrors(tempErrors);
  
      if(result){
        if(!mappingList.length){
          result = false;
          toast.error("No User Selected")
        }else{
          
          let currentUserList = props?.userList?.user_list || [];
    
          mappingList.forEach((ele)=>{
              let currentUser = currentUserList.find( u => u.userId === ele.user_id);
              if(currentUser){
                  if(Role[currentUser.reporting_to_role] && Role[currentUser.reporting_to_role] !== ele.manager_role_id){
                    errorList.push(ele)
                  }
              }
          })
    
          if(errorList.length){
            result = false;
            toast.error(`User's manager type can not be updated.`)
          }
        }   
      }
  
      return result;
    };
  
    const handleChangeMappingSubmit =(e)=>{
      setErrors({});
      e.preventDefault();   
      if (validateForm()) {
          setLoading(true);
          let params = {"mapping_list":mappingList}
          let logged_data = localStorage.getItem("logged_data")?JSON.parse(localStorage.getItem("logged_data")):{}
          if(logged_data.id){
              params.userId = logged_data.id
          }
          MasterService.post('/sfa/user/bulk_mapping_update',params)
          .then(function (response) { 
              if(response.data.status===200){
                  setLoading(false);                              
                  toast.success(response.data.message);
              }else{
                  setLoading(false);
                  toast.error(response.data.message);
              }                   
          })
          .catch(function (response) {   
              setLoading(false);
              toast.error(response.data.message);        
          });
      }
    }
  
    return (<>
          {loading ? <Loader/>: null }
          <form method="post" onSubmit={handleChangeMappingSubmit} style={{marginTop:"10px"}}> 
            <div className="row" style={{height:"100%"}}>
              <fieldset className="single-select col-md-6">
                <div className="material">
                  <Select components={{ ValueContainer: CustomValueContainer }}
                    id="reporting_role_id"
                    name="reporting_role_id"
                    onChange={handleReportingRoleChange}
                    options={reportingRole}
                    value={reportingRole.filter(({ id }) => id === reportingRoleId)}
                    getOptionLabel={({ name }) => name}
                    getOptionValue={({ id }) => id}
                    placeholder="Reporting to Role"
                    className="react-select"
                    classNamePrefix="react-select"
                    isDisabled={props.currentChangeManagerRole ? true : false}
                  />
                  {
                    (errors && errors.reporting_to_role) ? <span className="error show">{errors.reporting_to_role}</span> : ''
                    }
                </div>
              </fieldset>
  
              <fieldset className="single-select col-md-6">
                <div className="material">
                  <Select components={{ ValueContainer: CustomValueContainer }}
                    id="reporting_to"
                    name="reporting_to"
                    onChange={handleReportingToChange}
                    options={reportingTo}
                    value={reportingTo.filter(({ id }) => id === reportingUserId)}
                    getOptionLabel={({ name }) => name}
                    getOptionValue={({ id }) => id}
                    placeholder="Reporting To User"
                    className="react-select"
                    classNamePrefix="react-select"
                    isDisabled={reportingRoleId ? false : true}
                  />
                  {
                    (errors && errors.reporting_to) ? <span className="error show">{errors.reporting_to}</span> : ''
                    }
                </div>
              </fieldset>
            </div>
            <div className='row' style={{marginBottom:"10px",marginTop:"50px"}}>
                <div className="col-md-12 text-center" style={{display:"flex", justifyContent:"center"}}>
                    <button type="submit" className="btn-primary text-center">Update</button>
                </div>
            </div>
            </form>
      </>);
  };

  export default ChangeMappingPopUp;